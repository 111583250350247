import { Component } from 'react';

import github from '../assets/github.svg';
import linkedin from '../assets/linkedin.svg';
import instagram from '../assets/instagram.svg';
import email from '../assets/email.svg';


const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const openEmail = (url) => {
    window.location.href = `mailto:$url`;
}

class Footer extends Component {

    constructor (props) {
        super(props)
        this.state = {}
    }
  
    render () {
        return (
            <div className="footer">
                <div className="footer-content">
                    <div className="bottom-link-container">
                        <img className="contact-icon" style={{marginLeft: "16px"}} src={github} alt="github" onClick={() => openInNewTab('https://github.com/josh9595')} />
                        <img className="contact-icon" src={linkedin} alt="linked in" onClick={() => openInNewTab('https://www.linkedin.com/in/joshuaeverett95')} />
                        <img className="contact-icon" src={instagram} alt="instagram" onClick={() => openInNewTab('https://www.instagram.com/joshuaeverett_/')} />
                        <img className="contact-icon" src={email} alt="email" onClick={() => openEmail('mailto:joshuaeverett95@gmail.com')} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer