import { Component } from 'react';
import { Link } from "react-router-dom";

import logo from '../assets/logo.svg';

class Header extends Component {

    constructor (props) {
      super(props)
      this.state = {}
    }
  
    render () {
        return (
            <div className="header">
                <div className="header-content">
                    <div className="top-bar-container">
                        <Link to="/" className="logo-container">
                            <img className="logo" src={logo} alt="Joshua Everett dot co dot uk" />
                        </Link>
                        <div className="link-container">
                            <div>
                            <Link to="/about">
                                <div className="link">About</div>
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Header