import { Component } from 'react';
import josh from '../assets/josheverettlong.png';
import cv from '../assets/josh-everett-cv.pdf'

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

class About extends Component {

    constructor (props) {
        super(props)
        this.state = {}
    }
  
    render () {
        return (
            <div className="content">
                <div className="content-container">
                    <div className="about-container">
                        <div className="about-title">About me </div>
                        <div className="about-content-container">
                            <div className="about-text-container">
                                <div className="section-content">
                                    <div className="about-text">
                                        <div className="section-text">I’m Josh Everett and I’m a software engineer with 10 years of experience based in Manchester. My specialism is in Android engineering, with expertise in user interface and accessibility.</div>
                                    </div>
                                    <div className="about-text">
                                        <div className="section-text">My journey started with my degree at Loughborough University where I studied web development. This took me to Germany where I worked for a year during my degree embracing the experience of living abroad. After graduating I worked for the BBC; initially as part of the graduate scheme before becoming a permanent member of BBC Sport followed by BBC iPlayer, where I worked as a senior Android engineer. Most recently I worked for Deliveroo, in the team responsible for the design system working again as a senior android engineer, which has allowed me to continue building expertise in areas such as UI and accessibility.</div>
                                    </div>
                                    <div className="about-text">
                                        <div className="section-text">In my own time I love to be as creative as possible, recently getting into photography as well as doing all sorts of creative projects with my partner, such as drawing or painting. I’m a massive fan of many different sports in particular watching Formula 1 every Sunday. Music also plays a big part of my life, whether it’s playing my bass or guitar, at gigs or adding to my ever growing collection of vinyl records.</div>
                                    </div>
                                    <div className="section-link" style={{marginInlineStart:"32px", marginTop:"16px", marginBottom:"16px"}} onClick={() => openInNewTab(cv)}>Download my CV</div>
                                </div>
                            </div>
                            <div className="about-image-container">
                                <img className="about-image" src={josh} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default About