import { Component } from 'react';
import { Link } from "react-router-dom";

import josh from '../assets/josheverett.png';
import { projects } from '../data/projects.json';

function upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class Home extends Component {

    constructor (props) {
        super(props)
        this.state = {
            selectedProject: "all",
            projects: projects
        }
    }

    selectFilter = (filter) => {
        this.setState({
            selectedProject: filter,
            projects: this.getProjects(filter)
        })
    }

    getProjects = (filter) => {
        if (filter === "all") {
            return projects
        } else {
            return projects.filter(function (project){ return project.type === filter})
        }
    }
  
    render () {
        return (
            <div>
                <div className="home-header">
                    <div className="header-centered-content-container">
                    <div className="header-centered-content">
                        <img className="header-image" src={josh} alt="" />
                        <h1 className="header-title">Hi, I'm Josh</h1>
                        <h2 className="header-subtitle">I build Android apps, websites and more</h2>
                    </div>
                    </div>
                </div>

                <div className="content">
                    <div className="content-container">
                        <div className="home-filters-container">
                            <div className="section-filter" style={{cursor:"unset"}}>Filter:</div>
                            <div className="section-filter" style={{textDecoration: this.state.selectedProject === "all" ? "underline" : "none"}} onClick={() => this.selectFilter("all")}>All</div>
                            <div className="section-filter" style={{textDecoration: this.state.selectedProject === "work" ? "underline" : "none"}} onClick={() => this.selectFilter("work")}>Work</div>
                            <div className="section-filter" style={{textDecoration: this.state.selectedProject === "website" ? "underline" : "none"}} onClick={() => this.selectFilter("website")}>Websites</div>
                            <div className="section-filter" style={{textDecoration: this.state.selectedProject === "personal" ? "underline" : "none"}} onClick={() => this.selectFilter("personal")}>Personal</div>
                        </div>

                        <div className="home-projects-container">
                            {this.state.projects.map( project => 
                                <Link to={`/project/${project.id}`} key={project.id}>
                                    <div className="home-project-container">
                                        <div className="home-project-image-container">
                                            <img 
                                                className="home-project-image" 
                                                src={require(`../assets/${project.image}`).default}
                                                alt={project.id}/>
                                            <div className="home-project-image-tag">{upperFirst(project.type)}</div>
                                        </div>
                                        
                                        <div className="section-title">{project.name}</div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Home