import { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { Link } from "react-router-dom";

import { projects } from '../data/projects.json';
import arrowback from '../assets/arrow-back.svg';
import arrowforward from '../assets/arrow-forward.svg';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

var project = {}
var back = null
var forward = null

class Project extends Component {

    constructor (props) {
        super(props)
        this.state = {}

        const { params } = this.props.match
        projects.map((projectItem, index) => {
            if(projectItem.id === params.id) {
                console.log(projectItem)
                project = projectItem

                if (index === 0) {
                    forward = projects[1]
                    back = null
                } else if (index === projects.length - 1) {
                    forward = null
                    back = projects[projects.length - 2]
                } else {
                    forward = projects[index + 1]
                    back = projects[index - 1]
                }
            }
        })
    }

    componentWillReceiveProps(newProps) {
        projects.map((projectItem, index) => {
            if(projectItem.id === newProps.match.params.id) {
                console.log(projectItem)
                project = projectItem

                if (index === 0) {
                    forward = projects[1]
                    back = null
                } else if (index === projects.length - 1) {
                    forward = null
                    back = projects[projects.length - 2]
                } else {
                    forward = projects[index + 1]
                    back = projects[index - 1]
                }
            }
        })
    }

    
  
    render () {
        return (
            <div className="content">
                <div className="content-container">
                    <div className="project-container">
                        <div className="project-header-container">
                            <div className="project-header-image-container">
                                <img className="project-image" src={require(`../assets/${project.image}`).default} alt="" />
                            </div>
                            <div className="project-header-text-container">
                                <div className="project-title">{project.name}</div>

                                <div className="project-detail-title">{project.project.title}</div>
                                <div className="project-detail-value">{project.project.value}</div>

                                { project.role !== undefined && 
                                    <>
                                        <div className="project-detail-title">Role</div>
                                        <div className="project-detail-value">{project.role}</div>
                                    </>
                                }

                                <div className="project-detail-title">Year</div>
                                <div className="project-detail-value">{project.year}</div>

                                <div className="project-body-container">
                                    <div className="section-text">{ReactHtmlParser(project.description)}</div>
                                </div>
                                <div className="section-link" onClick={() => openInNewTab(project.link.url)}>{project.link.text}</div>
                                <div className="project-nav-container">
                                    { back != null && <div className="project-nav-back" style={{cursor:"pointer"}}>
                                        <img className="project-nav-icon" src={arrowback} />
                                        <Link to={`/project/${back.id}`}>
                                            <div className="section-filter" style={{textDecoration:"underline"}}>{back.name}</div>
                                        </Link>
                                    </div>}
                                    { forward != null && <div className="project-nav-forward" style={{cursor:"pointer"}}>
                                        <img className="project-nav-icon" src={arrowforward} />
                                        <Link to={`/project/${forward.id}`}>
                                            <div className="section-filter" style={{textDecoration:"underline"}}>{forward.name}</div>
                                        </Link>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Project