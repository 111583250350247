import { HashRouter, Route, Switch } from "react-router-dom";

import './App.css';
import ScrollToTop from './scroll-to-top';
import Header from './header/header';
import Home from './home/home';
import Project from './project/project';
import About from './about/about';
import Footer from './footer/footer';

function App() {
    return (
        <HashRouter basename="/">
            <ScrollToTop />
            <div className="App">
                <Header />
                <Switch>
                    <Route path="/" exact render={(props) => <Home />} />
                    <Route path="/project/:id" exact render={(props) => <Project match={props.match} />} />
                    <Route path="/about/" exact render={(props) => <About />} />
                </Switch>
                <Footer />
            </div>
        </HashRouter>
    );
}

export default App;
